import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c('div',{staticStyle:{"padding":"20px"}},[_c('XwSearch',{attrs:{"form-config":_vm.searchConfig},on:{"search":_vm.search}})],1),_c('div',{staticStyle:{"padding":"0 20px 20px 20px"}},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.handleUpload}},[_vm._v(" 上传 ")])],1),_c('div',{staticStyle:{"padding":"0 20px"}},[(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":""}}):_vm._e(),_c('XwTable',{ref:"tableRef",attrs:{"columns":_vm.tableColumns,"rows":_vm.pageData},scopedSlots:_vm._u([{key:"video",fn:function(ref){
var item = ref.row;
return [_c('div',{staticClass:"video-body mt8",staticStyle:{"width":"100px"}},[(item.videoPath && item.aliBucket)?_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":_vm.aliOssClientMap[item.aliBucket] && _vm.aliOssClientMap[item.aliBucket].fetchDownloadLink(item.videoPath),"target":"_blank"}},[_c(VImg,{staticStyle:{"background":"#eee","border-radius":"4px"},attrs:{"src":item.imgPath && _vm.aliOssClientMap[item.aliBucket]? _vm.aliOssClientMap[item.aliBucket].fetchDownloadLink(item.imgPath): _vm.defo,"height":"50","width":"89"}})],1):_c('img',{staticStyle:{"width":"89px","height":"50px"},attrs:{"src":_vm.defo}}),_c('div',{staticStyle:{"height":"22px"}},[(item.videoPath && item.aliBucket)?_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":_vm.aliOssClientMap[item.aliBucket] && _vm.aliOssClientMap[item.aliBucket].fetchDownloadLink(item.videoPath),"target":"_blank"}},[_vm._v(_vm._s(item.title))]):_c('span',[_vm._v(_vm._s(item.title))])])])]}},{key:"time",fn:function(ref){
var item = ref.row;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"colTime font-weight-medium text--primary text-truncate"},[_vm._v(" 创建时间："+_vm._s(item.createdAt?_vm.resolveTime(item.createdAt):'-')+" ")]),_c('div',{staticClass:"colTime font-weight-medium text--primary text-truncate"},[_vm._v(" 上传时间："+_vm._s(item.uploadAt ? _vm.resolveTime(item.uploadAt) : '-')+" ")]),_c('div',{staticClass:"colTime font-weight-medium text--primary text-truncate"},[_vm._v(" 预设发布时间："+_vm._s(item.releaseAt?_vm.resolveTime(item.releaseAt):'-')+" ")])])]}},{key:"status",fn:function(ref){
var item = ref.row;
return [_c('XwStatus',{attrs:{"status":item.status,"text":_vm.resolveStatusText(item.status)}})]}},{key:"actions",fn:function(ref){
var item = ref.row;
return [([6].includes(item.status))?_c('a',{staticClass:"ml-4",on:{"click":function($event){return _vm.handleRetry(item)}}},[_vm._v(" 重试 ")]):_vm._e(),_c('a',{on:{"click":function($event){return _vm.handleJump(item)}}},[_vm._v(" 设置/详情 ")])]}}])})],1),(_vm.pageLength)?_c('div',{staticStyle:{"padding":"10px 20px"}},[_c('new-pagination',{attrs:{"page-index":_vm.page,"page-size":_vm.pageSize,"total":_vm.pageLength},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1):_vm._e(),(_vm.showDetail)?_c('Detail',{attrs:{"is-add-new-sidebar-active":_vm.showDetail,"curr-item":_vm.currItem,"can-edit":[0, 1, 6].includes(_vm.currItem.status) || !_vm.currItem.status},on:{"update:isAddNewSidebarActive":function($event){_vm.showDetail=$event},"update:is-add-new-sidebar-active":function($event){_vm.showDetail=$event},"success":_vm.fetchList}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }